import React from 'react'
import { Link } from 'gatsby'

const Footer = () => {
  return (
    <div className="bg-white">
      <div className="max-w-screen-xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center">
          <div className="px-5 py-2">
            <Link to={"/"} className="text-base leading-6 text-gray-500 hover:text-gray-900">
              Home
            </Link>
          </div>
          <div className="px-5 py-2">
            <Link to={"/services"} className="text-base leading-6 text-gray-500 hover:text-gray-900">
              Services
            </Link>
          </div>
          <div className="px-5 py-2">
            <Link to={"/contact"} className="text-base leading-6 text-gray-500 hover:text-gray-900">
              Contact
            </Link>
          </div>
        </nav>
        <div className="mt-8">
          <p className="text-center text-base leading-6 text-gray-400">
            &copy; 2020 Lanternx, Inc. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer;
