import React from 'react'
import { useForm } from '@formcarry/react'

import Layout from "../components/layout";
import SEO from "../components/seo";
import Footer from "../components/footer";

const Contact = () => {

  const { state, submit } = useForm({
    id: 'iBTA_RA-oThw'
  })

    if (state.submitted) {

    return <div>Thank you! We received your submission.</div>;

  }

  return (
    <Layout>
      <SEO title="Contact Us" />
      <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
        <div className="relative max-w-xl mx-auto">
          <div className="text-center">
            <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              Free Consultation
            </h2>
            <p className="mt-4 text-lg leading-6 text-gray-500">
              Have a project you're ready to talk about? We'd be glad to connect.
            </p>
          </div>
          <div className="mt-12">
            <form onSubmit={submit} className="grid grid-cols-1 row-gap-6 sm:grid-cols-2 sm:col-gap-8">
              <div>
                <label htmlFor="first_name" className="block text-sm font-medium leading-5 text-gray-700">First
                  name</label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input id="first_name" name="first_name"
                         className="form-input py-3 px-4 block w-full transition ease-in-out duration-150"/>
                </div>
              </div>
              <div>
                <label htmlFor="last_name" className="block text-sm font-medium leading-5 text-gray-700">Last
                  name</label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input id="last_name" name="last_name"
                         className="form-input py-3 px-4 block w-full transition ease-in-out duration-150"/>
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="company" className="block text-sm font-medium leading-5 text-gray-700">Company</label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input id="company" name="company"
                         className="form-input py-3 px-4 block w-full transition ease-in-out duration-150"/>
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">Email</label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input id="email" type="email" name="email"
                         className="form-input py-3 px-4 block w-full transition ease-in-out duration-150"/>
                </div>
              </div>
              {/*<div className="sm:col-span-2">*/}
              {/*  <label htmlFor="phone_number" className="block text-sm font-medium leading-5 text-gray-700">Phone*/}
              {/*    Number</label>*/}
              {/*  <div className="mt-1 relative rounded-md shadow-sm">*/}
              {/*    <div className="absolute inset-y-0 left-0 flex items-center">*/}
              {/*      <select aria-label="Country"*/}
              {/*              className="form-select h-full py-0 pl-4 pr-8 border-transparent bg-transparent text-gray-500 transition ease-in-out duration-150">*/}
              {/*        <option>US</option>*/}
              {/*        <option>CA</option>*/}
              {/*        <option>EU</option>*/}
              {/*      </select>*/}
              {/*    </div>*/}
              {/*    <input id="phone_number"*/}
              {/*           className="form-input py-3 px-4 block w-full pl-20 transition ease-in-out duration-150"*/}
              {/*           placeholder="+1 (555) 987-6543"/>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className="sm:col-span-2">
                <label htmlFor="message" className="block text-sm font-medium leading-5 text-gray-700">Message</label>
                <div className="mt-1 relative rounded-md shadow-sm">
                            <textarea id="message" rows="4" name="message"
                                      className="form-textarea py-3 px-4 block w-full transition ease-in-out duration-150"></textarea>
                </div>
              </div>
              {/*<div className="sm:col-span-2">*/}
              {/*  <div className="flex items-start">*/}
              {/*    <div className="flex-shrink-0">*/}
              {/*      <span role="checkbox" tabIndex="0" aria-checked="false"*/}
              {/*            className="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline">*/}
              {/*      <span aria-hidden="true"*/}
              {/*            className="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200"></span>*/}
              {/*      </span>*/}
              {/*    </div>*/}
              {/*    <div className="ml-3">*/}
              {/*      <p className="text-base leading-6 text-gray-500">*/}
              {/*        By selecting this, you agree to the*/}
              {/*        <a href="#" className="font-medium text-gray-700 underline">Privacy Policy</a>*/}
              {/*        and*/}
              {/*        <a href="#" className="font-medium text-gray-700 underline">Cookie Policy</a>.*/}
              {/*      </p>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className="sm:col-span-2">
          <span className="w-full inline-flex rounded-md shadow-sm">
            <button type="submit"
                    className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition ease-in-out duration-150">
              Let's talk
            </button>
          </span>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
          <div className="grid grid-cols-1 gap-16">
            {/*<div className="lg:grid lg:grid-cols-3 lg:gap-8">*/}
            {/*  <h2 className="text-2xl leading-8 font-extrabold text-gray-900 sm:text-3xl sm:leading-9">*/}
            {/*    Get in touch*/}
            {/*  </h2>*/}
            {/*  <div*/}
            {/*    className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:col-gap-8 sm:row-gap-12 lg:mt-0 lg:col-span-2">*/}
            {/*    <div>*/}
            {/*      <h4 className="text-lg leading-6 font-medium text-gray-900">*/}
            {/*        Collaborate*/}
            {/*      </h4>*/}
            {/*      <dl className="mt-2 text-base leading-6 text-gray-500">*/}
            {/*        <div>*/}
            {/*          <dt className="sr-only">*/}
            {/*            Email*/}
            {/*          </dt>*/}
            {/*          <dd>*/}
            {/*            support@example.com*/}
            {/*          </dd>*/}
            {/*        </div>*/}
            {/*        <div className="mt-1">*/}
            {/*          <dt className="sr-only">*/}
            {/*            Phone number*/}
            {/*          </dt>*/}
            {/*          <dd>*/}
            {/*            +1 (555) 123-4567*/}
            {/*          </dd>*/}
            {/*        </div>*/}
            {/*      </dl>*/}
            {/*    </div>*/}

            {/*    <div>*/}
            {/*      <h4 className="text-lg leading-6 font-medium text-gray-900">*/}
            {/*        Say hello*/}
            {/*      </h4>*/}
            {/*      <dl className="mt-2 text-base leading-6 text-gray-500">*/}
            {/*        <div>*/}
            {/*          <dt className="sr-only">*/}
            {/*            Email*/}
            {/*          </dt>*/}
            {/*          <dd>*/}
            {/*            support@example.com*/}
            {/*          </dd>*/}
            {/*        </div>*/}
            {/*        <div className="mt-1">*/}
            {/*          <dt className="sr-only">*/}
            {/*            Phone number*/}
            {/*          </dt>*/}
            {/*          <dd>*/}
            {/*            +1 (555) 123-4567*/}
            {/*          </dd>*/}
            {/*        </div>*/}
            {/*      </dl>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="border-t-2 border-gray-100"></div>
            <div className="lg:grid lg:grid-cols-3 lg:gap-8">
              <h2 className="text-2xl leading-8 font-extrabold text-gray-900 sm:text-3xl sm:leading-9">
                Locations
              </h2>
              <div
                className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:col-gap-8 sm:row-gap-12 lg:mt-0 lg:col-span-2">
                <div>
                  <h4 className="text-lg leading-6 font-medium text-gray-900">
                    Bangkok
                  </h4>
                  <div className="mt-2 text-base leading-6 text-gray-500">
                    <p>
                      1/1 Soi 8 Sukhumvit 70/3
                    </p>
                    <p className="mt-1">
                      Bang Na, Thailand 10260
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default Contact;
